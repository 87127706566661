import React from 'react';
import ReactGA from 'react-ga';
// import aquaSmall from './images/MozaquaLogo-small.svg';
// import longlineLogo from './images/Longline_logo_web.svg';
// import movGovtLogo from './images/mov-govt-logo.svg';
import './styles/App.scss';
import Map from './Map';
import SidebarLocations from './SidebarLocations';
import Loader from './Loader';
import layers from './constants/layers.json';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import 'antd/lib/checkbox/style/index.css';
import 'antd/lib/collapse/style/index.css';
import findIndex from 'lodash.findindex';
import find from 'lodash.find';
import set from 'lodash.set';
import { strings } from '../src/constants/localization';
import koeffPonds from './constants/ponds_coeff.json';

// ReactGA.initialize('G-GKQ5Z5X4G0', {
//   gaOptions: {
//     siteSpeedSampleRate: 100
//   }
// });

const barColors = ['#E37400', '#1A73E8', '#34A853', '#00DFFF', '#FBBC04', '#9DCD67'];

const activityChartData = {
  datasets: [{
    data: [],
    backgroundColor: barColors,
    borderWidth: 0,
  }],
  labels: []
};

const activitiesBarChartOptions = {
  maintainAspectRatio: false,
  title: {
    display: true,
    text: '',
    fontColor: 'gray'
  },
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: true,
      color: 'gray',
      anchor: 'end',
      align: 'end'
    }
  },
  scales: {
    yAxes: [{
      ticks: {
        fontColor: "rgba(0, 0, 0, 0.7)",
        stepsize: 1000,
        maxTicksLimit: 5
      }
    }],
    xAxes: [{
      ticks: {
        fontColor: "rgba(0, 0, 0, 0.7)",
        stepsize: 1000,
        maxRotation: 25
      }
    }]
  }
};

const mapPositionDefault = {lat: -15.707184, lng: 31.684791, zoom: 8};

class App extends React.Component {
  constructor() {
    super();

    ReactGA.pageview(window.location.pathname);

    this.state = {
      mapPosition: mapPositionDefault,
      sidebarLayers: [ ...layers ],
      layers: [],
      loadingLayers: false,
      context: {type: null, lat: null, lng: null, info: {}},
      contextLocked: false,
      koeffPonds: [ ...koeffPonds ],
      basin: undefined
    };
  }

  updateMapPosition = async (locationKey) => {
    const location = find(this.state.sidebarLayers, { 'key': locationKey });
    
    await this.setStateAsync({ 
      ...this.state, 
      mapPosition: mapPositionDefault
    });
    await this.sleep(300);
    if (location.mapPosition === this.state.mapPosition) {
      await this.setStateAsync({ 
        ...this.state, 
        mapPosition: { ...location.mapPosition, zoom: 10 }
      });
    } 
    else {
      this.setStateAsync({ 
        ...this.state, 
        mapPosition: location.mapPosition
      });
    }    
  }

  sidebarLayerState = (locationIndex, currentState, keyPath, checked) => {
    let newState = [ ...currentState ];
    const keys = keyPath.split('.');    
    let path = newState[locationIndex];
    let keyIndices = [locationIndex.toString()];
    let isHeading = true;
    keys.map(key => {
      const headingKey = find(path.headings, { 'key': key });
      if (headingKey) {
        let index = findIndex(path.headings, { 'key': key });
        keyIndices.push("headings");
        keyIndices.push(index.toString());
        path = headingKey;
      } else {
        let index = findIndex(path.layers, { 'key': key });
        keyIndices.push("layers");
        keyIndices.push(index.toString());
        path = find(path.layers, { 'key': key });
        isHeading = false;
      }
      return path;
    });

    if (isHeading) {
      path.headings.forEach(heading => {
        newState = this.sidebarLayerState(
          locationIndex,
          [ ...newState ],
          keyPath.concat(`.${heading.key}`),
          checked
        )
      });

      path.layers.forEach(layer => {
        newState = this.sidebarLayerState(
          locationIndex,
          [ ...newState ],
          keyPath.concat(`.${layer.key}`),
          checked
        )
      });
    }

    set(
      newState,
      keyIndices.concat(['isChecked']),
      checked
    );

    return newState;
  }

  onLayerCheckChange = async (e, locationKey, keyPath) => {
    const locationIndex = findIndex(this.state.sidebarLayers, { 'key': locationKey });
    const newSidebarState = this.sidebarLayerState(
      locationIndex,
      [ ...this.state.sidebarLayers ],
      keyPath,
      e.target.checked
    );
    
    await this.setStateAsync({ ...this.state, loadingLayers: true });
    await this.setStateAsync({ ...this.state, sidebarLayers: newSidebarState });
    await this.setStateAsync({ 
      ...this.state,
      layers: await this.state.sidebarLayers.reduce(this.getCheckedLayers, [])
    });
    await this.setStateAsync({ ...this.state, loadingLayers: false });
  }

  onLayerBasinChange = async(e, basin) => {
    await this.setStateAsync({ ...this.state, basin: basin });

    await this.setStateAsync({ ...this.state, loadingLayers: true });
    // await this.setStateAsync({ ...this.state, sidebarLayers: [...oldSidebarLayersState]});
    await this.setStateAsync({ 
      ...this.state,
      layers: []
    });
    await this.setStateAsync({ 
      ...this.state,
      layers: await this.state.sidebarLayers.reduce(this.getCheckedLayers, [])
    });
    await this.setStateAsync({ ...this.state, loadingLayers: false });
  }

  // TODO: pull this out into util
  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getCheckedLayers = async (result, heading) => {
    result = await heading.headings.reduce(this.getCheckedLayers, result);
    const checkedLayers = await heading.layers
    .filter(layer => layer.isChecked)
    .map(async (layer) => {
      // if (layer.data === null) {
        await fetch(layer.source)
        .then((res) => res.json())
        .then((data) => {
          layer.data = this.state.basin && (layer.key !== 'rivers') ? { features: data.features.filter(item => item.properties.basin === this.state.basin) } : data
        });
      // }
      return layer;
    });

    const loadedLayers = await Promise.all(checkedLayers)
    .then((result) => result);

    return result.concat(loadedLayers);
  }

  isLayerChecked = (layerKey) => {
    return findIndex(this.state.layers, (layer) => { return layer.key === layerKey }) > -1;
  }

  onContextChange = (context) => {
    if (!this.state.contextLocked) {
      this.setState({
        ...this.state,
        context: context
      });
    }
  }

  onLockContext = (context) => {
    if (this.state.contextLocked) {
      this.onUnlockContext();
    } else {
      this.setState({
        ...this.state,
        context: context,
        contextLocked: true
      });
    }
  }

  toggleActiveTotalStats(type) {
    if (this.state.activeTotalStats !== type) {
      this.setState({activeTotalStats: type})
    }
  }

  onUnlockContext = () => {
    this.setState({ 
      ...this.state,
      contextLocked: false 
    });
  }

  round2 = (n) => {
    return parseFloat(n) ? parseFloat(n).toFixed(2) : null
  }
 
  round0 = (n) => {
    return parseFloat(n) ? parseFloat(n).toFixed(0) : null
  }

  thousandsSeparator = (n) => {
    return parseFloat(n) ? parseFloat(n).toLocaleString().replace(/,/g," ",) : null
  }

  m2ToHa = (n) => {
    return parseFloat(n) ? parseFloat(n)/10000 : null
  }

  m2ToRai = (n) => {
    return parseFloat(n) ? (parseFloat(n)/10000)*16 : null
  }

  kgToTon = (n) => {
    return parseFloat(n) ? parseFloat(n)/1000 : null
  }
  

  render() {
    let activitiesBarChart = (context) => {
      const chartOptions = {
        ...activitiesBarChartOptions
      };

      const chartData = {
        labels: [
          strings.gill_net_fisheries,
          strings.commercial_aquaculture,
          strings.subsistence_fisheries,
          strings.small_scale_aquaculture,
          strings.semi_industrial_fisheries,
          strings.sport_fisheries
        ],
        datasets: Object.assign([...activityChartData.datasets], {
          0: {
            ...activityChartData.datasets[0],
            data: [
              this.round2(context.info["area_AC"]), 
              this.round2(context.info["area_AQ"]), 
              this.round2(context.info["area_AS"]), 
              this.round2(context.info["area_SCA"]),
              this.round2(context.info["area_SI"]),
              this.round2(context.info["area_SP"])
            ]
          }
        })
      };
      // if (context.info["NH3_umol/L"] || context.info["DIN_umol/L"] || context.info["Phy_ugCh/L"] || context.info["Zoo_mgFWm3"]) {
        return (
          <div className="chart">
            <Bar 
              data={ chartData }
              options={ chartOptions } />
          </div>
        )
      // }
    }

    let displayContext = () => {
      const context = this.state.context;

      switch (context.type) {
        case 'zone':
          return (
            <div className="">
              <div>
                <span className="font-weight-bold">{strings.zone}: </span>
                {context.info.zone}
              </div>
              <div>
                <span className="font-weight-bold">{strings.basin}: </span>
                {context.info.basin}
              </div>
              <div>
                <span className="font-weight-bold">{strings.areaKm2}: </span>
                {this.round2(context.info["area_km2"])}
              </div>

              <div className="context-charts">
                { activitiesBarChart(context) }
              </div>
            </div>
          );
        case 'fishery':
          return (
            <div>
              <div>
                <span className="font-weight-bold">{strings.zone}: </span>
                {context.info.zone}
              </div>
              <div>
                <span className="font-weight-bold">{strings.basin}: </span>
                {context.info.basin}
              </div>

              {context.info.area_AC ? 
                <div>
                  <span className="font-weight-bold">{strings.areaKm2}: </span>
                  {this.round2(context.info.area_AC)}
                </div>
              : null}
              {context.info.area_AQ ? 
                <div>
                  <span className="font-weight-bold">{strings.areaKm2}: </span>
                  {this.round2(context.info.area_AQ)}
                </div>
              : null}
              {context.info.area_AS ? 
                <div>
                  <span className="font-weight-bold">{strings.areaKm2}: </span>
                  {this.round2(context.info.area_AS)}
                </div>
              : null}
              {context.info.area_SI ? 
                <div>
                  <span className="font-weight-bold">{strings.areaKm2}: </span>
                  {this.round2(context.info.area_SI)}
                </div>
              : null}
              {context.info.area_SP ? 
                <div>
                  <span className="font-weight-bold">{strings.areaKm2}: </span>
                  {this.round2(context.info.area_SP)}
                </div>
              : null}
              {context.info.area_BS ? 
                <div>
                  <span className="font-weight-bold">{strings.areaKm2}: </span>
                  {this.round2(context.info.area_BS)}
                </div>
              : null}
            </div>
          );
        case 'river':
          return (
            <div className="">
              <div>
                <span className="font-weight-bold">{strings.river}: </span>
                {context.info.TOPONIMO}
              </div>
            </div>
          );
        default:
          return (
            <div>
              <div>
                <span className="font-weight-bold">{strings.zone}: </span>
                {context.info.zone}
              </div>
              <div>
                <span className="font-weight-bold">{strings.basin}: </span>
                {context.info.basin}
              </div>
            </div>
          );
      }
    }

    return (
      <div className="app">
        <div className="section map">
          <div className="map-columns">
            <SidebarLocations 
              class="sidebar show"
              layers={ this.state.sidebarLayers }
              isLoading={ this.state.loadingLayers }
              onNavigate={ this.updateMapPosition }
              onCheckChange={ this.onLayerCheckChange }
              onBasinChange={ this.onLayerBasinChange } />
            <div className="map-and-context">
              <div className="map-wrapper">
                <Map 
                  position={this.state.mapPosition}
                  layers={this.state.layers} 
                  onContextChange={this.onContextChange}
                  onClick={this.onLockContext} />
              </div>

              { Object.keys(this.state.context.info).length > 0 ? (
                <div className="context show">
                  { this.state.loadingLayers ? (
                    <div className="loader-padding">
                      <Loader status="Loading layers" inlineStatus={false} />
                    </div>
                  ) : (
                    <div className="h-100">
                      <div>
                        { displayContext() }
                      </div>
                    </div>
                  )}
                </div>
              ) : null }
            </div>
          </div>
        </div>
        <div className="section about">

        </div>
      </div>
    );
  }
}

export default App;
