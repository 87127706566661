import React from 'react';
import { PropTypes } from 'prop-types';
import { 
  Map as LeafletMap,
  TileLayer,
  GeoJSON,
  LayersControl,
  Tooltip
  // ZoomControl
} from 'react-leaflet';
import { GoogleLayer } from 'react-leaflet-google-v2';
import { strings } from './constants/localization';
const { BaseLayer } = LayersControl;
const key = 'AIzaSyAIHYcBsiW_mHme3t0_BO4WCj3qqgtaINc';

class Map extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      lat: null,
      lng: null,
      info: null
    }
  };

  componentDidUpdate(prevProps) {
    // if (this.props.toolbars !== prevProps.toolbars) {
    //   const map = this.refs.map.leafletElement;
    //   map.invalidateSize();
    // }
  }

  byte2Hex = (byte) => {
    var hex = Number(byte).toString(16);
    if (hex.length < 2) {
      hex = "0" + hex;
    }
    return hex;
  };
  
  rgb2Hex = (r, g, b) => {
    var red = this.byte2Hex(r);
    var green = this.byte2Hex(g);
    var blue = this.byte2Hex(b);
    return '#' + red + green + blue;
  };

  getColor = (d) => {
    return d > 380 ? this.rgb2Hex(30, 178, 255) :
    d > 240 ? this.rgb2Hex(43, 186, 255) :
    d > 180 ? this.rgb2Hex(55, 193, 255) :
    d > 90 ? this.rgb2Hex(65, 200, 255) :
    d > 50 ? this.rgb2Hex(79, 210, 255) :
    d > 25 ? this.rgb2Hex(94, 223, 255) :
    d > 10 ? this.rgb2Hex(138, 227, 255) :
    this.rgb2Hex(51, 102, 0);
  }

  round2 = (n) => {
    return parseFloat(n) ? parseFloat(n).toFixed(2) : null
  }

  getFeatureType = (featureProperties) => {
    const keys = Object.keys(featureProperties);

    if (keys.indexOf('area_km2') > -1) {
      return 'zone';
    } else if ((keys.indexOf('DN') > -1) && (keys.indexOf('area_km2') < 0)) {
      return 'fishery';
    } else if (keys.indexOf('TOPONIMO') > -1) {
      return 'river';  
    } else {
      return 'other';
    }
  }

  onMouseOver = (e) => {
    // console.log(e.layer)
    let info = {
      type: this.getFeatureType(e.layer.feature.properties),
      lat: parseFloat(e.latlng.lat) ? parseFloat(e.latlng.lat).toFixed(5) : null,
      lng: parseFloat(e.latlng.lng) ? parseFloat(e.latlng.lng).toFixed(5) : null,
      info: e.layer.feature.properties
    }
    this.setState({...this.state, info: e.layer.feature.properties});
    this.props.onContextChange(info);
  }

  onMouseOut = (e) => {
    let info = {
      type: null,
      lat: null,
      lng: null,
      info: {}
    }

    this.props.onContextChange(info);
  }

  onClick = (e) => {
    let info = {
      type: this.getFeatureType(e.layer.feature.properties),
      lat: parseFloat(e.latlng.lat) ? parseFloat(e.latlng.lat).toFixed(5) : null,
      lng: parseFloat(e.latlng.lng) ? parseFloat(e.latlng.lng).toFixed(5) : null,
      info: e.layer.feature.properties
    }
    this.props.onClick(info);
  }

  render() {
    const position = [this.props.position.lat, this.props.position.lng];
    const zoom = this.props.position.zoom;
    console.log(this.state.info)
    let overlayLayers = this.props.layers.filter(layer => layer.isChecked && layer.data != null).map(layer => {
      return (
        <GeoJSON
          key={layer.key}
          data={layer.data}
          style={layer.style === "BATHYMETRY_STYLE" ? this.bathStyle : layer.style}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          onClick={this.onClick} >
          <Tooltip sticky>
            {this.state.info && this.state.info.zone ? 
              <div>{strings.zone}: {this.state.info.zone}</div>
            : null}
            {this.state.info && this.state.info.basin ? 
              <div>{strings.basin}: {this.state.info.basin}</div>
            : null}
            {this.state.info && this.state.info.area_km2 ? 
              <div>{strings.areaKm2}: {this.round2(this.state.info.area_km2)}</div>
            : null}
            {this.state.info && this.state.info.TOPONIMO ? 
              <div>{this.state.info.TOPONIMO}</div>
            : null}
          </Tooltip>

        </GeoJSON>
        
      )
    });

    return (
      <LeafletMap center={position} zoom={zoom} minZoom={6} ref="map">
        <LayersControl position="topright">
          <BaseLayer name="Base Map">
            <TileLayer class="attribution"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='http://{s}.tile.osm.org/{z}/{x}/{y}.png' />
          </BaseLayer>

          <BaseLayer name="Satellite" >
            <GoogleLayer googlekey={key} maptype="SATELLITE" />
          </BaseLayer>
          <BaseLayer name="Terrain" >
            <GoogleLayer googlekey={key} maptype="TERRAIN" />
          </BaseLayer>
          <BaseLayer name="Hybrid" checked>
            <GoogleLayer googlekey={key} maptype="HYBRID" libraries={['geometry', 'places']} />
          </BaseLayer>  

          {overlayLayers}
        </LayersControl>
      </LeafletMap>
    );
  }
}

Map.propTypes = {
  // toolbars: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  layers: PropTypes.array.isRequired,
  onContextChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Map;