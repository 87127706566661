import React from 'react';
import { PropTypes } from 'prop-types';
import { Icon } from 'antd';
import SidebarHeading from './SidebarHeading';
import aquaLogo from './images/MozaquaLogo.svg';
import { Image, NavDropdown, NavLink, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { languageCodes, strings } from '../src/constants/localization';
import longlineLogo from './images/Longline_logo_web.svg';
import movGovtLogo from './images/mov-govt-logo.svg';

// const { Panel } = Collapse;
const storageLanguageKey = 'language';

class SidebarLocations extends React.Component {
  state = {
    isShow: true,
    toolbars: {
      layers: {
        label: "Layers",
        isChecked: true
      },
      charts: {
        label: "Charts",
        isChecked: true
      }
      
    },
    languageLabel: languageCodes[strings.getLanguage()].label,
    basinList: [
      "Garganta",
      "Chicoa",
      "Màgoé",
      "Carinde",
      "Mucanha",
      "Mussenguezi",
      "Zumbo"
    ],
    basinSelected: '',
    showAbout: false
  }

  componentDidMount() {
    const preferredLanguage = localStorage.getItem(storageLanguageKey);
    if (preferredLanguage) {
      this.setLanguage(preferredLanguage);
    } else {
      this.setLanguage('pt');

    }
  }
  onToolbarCheckChange = (toolbarKey) => {
    this.setState({
      ...this.state,
      toolbars: {...this.state.toolbars,
        [toolbarKey]: {...this.state.toolbars[toolbarKey],
          isChecked: !this.state.toolbars[toolbarKey].isChecked
        }
      }
    });
  };
  setLanguage = (languageCode) => {
    strings.setLanguage(languageCode);
    localStorage.setItem(storageLanguageKey, languageCode);
    this.setState({ 
      ...this.state,
      languageLabel: languageCodes[strings.getLanguage()].label 
    });
  };

  handleOnCheckChange = (e, keyPath, locationKey) => {
    this.props.onCheckChange(e, locationKey, keyPath);
  }

  handleOnBasinChange = (e, basin) => {
    // console.log(basin);
    this.setState({ 
      ...this.state,
      basinSelected: basin
    });
    this.props.onBasinChange(e, basin);
  }

  capitalize = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  toggleActiveClass = () => {
    this.setState({isShow: !this.state.isShow});
    window.scrollTo({top:0})
  }

  handleAbout = () => {
    this.setState({...this.state, showAbout: !this.state.showAbout});
  }


  render() {
    const classActive = (classActiveProps) => {
      if (this.state.isShow && classActiveProps.includes('show')) {
        return 'sidebar show'
      } else {
        return 'sidebar'
      }
    }

    const mapNavigation = (location) => {
      return (
        <a href=" #" className="text-muted lead mx-auto line-height-1"
          onClick={event => {
            event.stopPropagation();
            this.props.onNavigate(location)
          }}>
          <Icon type="compass" />
        </a>
      )
    }

    // const toolbarOptions = Object.keys(this.state.toolbars).map(toolbarKey => {
    //   return (
    //     <NavDropdown.Item key={toolbarKey} onClick={() => this.onToolbarCheckChange(toolbarKey)} href="# ">
    //       <Checkbox 
    //         checked={ this.state.toolbars[toolbarKey].isChecked }
    //         onChange={() => this.onToolbarCheckChange(toolbarKey)}>
    //         { this.state.toolbars[toolbarKey].label }
    //       </Checkbox>
    //     </NavDropdown.Item>
    //   );
    // });
    const languageOptions = Object.keys(languageCodes).map(languageCode => {
      return (
        <NavDropdown.Item key={languageCode} onClick={ () => this.setLanguage(languageCodes[languageCode].code) } href="# ">
          { languageCodes[languageCode].label }
        </NavDropdown.Item>
      );
    });

    const headings = (headings, locationKey, layers) => {
      if (headings && headings.length) {
        return headings.map(heading => {
          const checkChange = (e, keyPath) => {
            this.handleOnCheckChange(e, keyPath, locationKey)
          }
  
          return (
            <SidebarHeading 
              key={ heading.key }
              isLoading={ this.props.isLoading }
              heading={ heading }
              layers={ heading.layers }
              locationKey={ locationKey }
              onCheckChange={ checkChange } />
          )
        });
      } else {
        const checkChange = (e, keyPath) => {
          this.handleOnCheckChange(e, keyPath, locationKey)
        }
        return (
          <SidebarHeading 
            key=''
            isLoading={ this.props.isLoading }
            heading={ null }
            layers={ layers }
            locationKey={ locationKey }
            onCheckChange={ checkChange } />
        )
      }
      
    }

    const locations = this.props.layers.map(location => {
      return (
        <div key={location.key} className="">
          { headings(location.headings, location.key, location.layers) }
          
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="right"
            overlay={<Tooltip>{strings.zoom}</Tooltip>}>
            <div className="sidebar-item">
              { mapNavigation(location.key) }
              {/* { this.capitalize(location.key) } */}
            </div>
          </OverlayTrigger>
        </div>
      )
    });

    const basinsOptions = this.state.basinList.map(basin => {
      return (
        <NavDropdown.Item 
          key={basin} 
          onClick={e => this.handleOnBasinChange(e, basin)}
          href="# ">
          { basin }
        </NavDropdown.Item>
      );
    });

    return (
      <div className="sidebar-wrapper">

        <div className={ classActive(this.props.class) }>
          <div className="sidebar-item"> 
            <Image className="logo" src={aquaLogo} />
          </div>
          <div>
            <div className="sidebar-item d-flex justify-content-between align-items-center">
              <div className="text-nowrap">
                <div className="text-muted lead d-inline-block line-height-1 ml-1">
                  <Icon type="environment"/>
                </div>
                <span className="pl-2 pr-3">{strings.basin}:</span>
              </div>
              <NavDropdown title={ this.state.basinSelected || strings.choose }>
                { basinsOptions }
              </NavDropdown>
            </div>
          </div>

          <div className="sidebar-item d-flex justify-content-between align-items-center">
            <div className="text-muted lead mx-2 line-height-1">
              <Icon type="global" theme="outlined" /> 
            </div>
            
            <NavDropdown title={ this.state.languageLabel } id="basic-nav-dropdown">
              { languageOptions }
            </NavDropdown>
          </div>

          { locations }
          
          {/* <div className="sidebar-item">
            <NavDropdown title="Toolbars">
              { toolbarOptions }
            </NavDropdown>
          </div> */}
          <div>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="right"
              overlay={<Tooltip>{strings.contacts}</Tooltip>}>
              <div className="sidebar-item">
                <NavLink 
                  href="https://www.longline.co.uk/flow/contacts/" 
                  target="blank" 
                  className="text-muted lead mx-auto line-height-1">
                  <Icon type="message" /> 
                </NavLink>
              </div>
            </OverlayTrigger>
          </div>
          <div>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="right"
              overlay={<Tooltip>{strings.about}</Tooltip>}>
              <div 
                className="sidebar-item"
                onClick={event => {
                  this.handleAbout();
                }}>
                <div 
                  className="text-muted lead mx-auto line-height-1">
                  <Icon type="info" /> 
                </div>
              </div>
            </OverlayTrigger>
          </div>
          <div>
            { this.state.showAbout ? (
              <div className="sidebar-item flex-wrap mb-0 px-3 py-2 justify-content-around">
                <p className="w-100 mb-0">{strings.partners}</p>
                <div className="text-nowrap">
                  <a href="http://www.mimaip.gov.mz/" target="_blank" rel="noopener noreferrer">
                    <Image 
                      className="mr-2" 
                      src={movGovtLogo} 
                      title="Mozambique" />
                  </a>
                  <a href="https://www.longline.co.uk/" target="_blank" rel="noopener noreferrer">
                    <Image 
                      className="" 
                      src={longlineLogo} 
                      title="Longline Environment" />
                  </a> 
                </div>
              </div>
            ) : null }
          </div>
        </div>
      </div>
    );
  }
}

SidebarLocations.propTypes = {
  class: PropTypes.string.isRequired,
  layers: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onCheckChange: PropTypes.func.isRequired,
  onBasinChange: PropTypes.func.isRequired
};

export default SidebarLocations;