import React from 'react';
import { PropTypes } from 'prop-types';
import { Checkbox, Icon } from 'antd';
import { strings } from '../src/constants/localization';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// const { Panel } = Collapse;

class SidebarHeading extends React.Component {

  handleOnCheckChange = (e, keyPath) => {
    // console.log(keyPath)
    if (keyPath) {
      this.props.onCheckChange(e, `${this.props.heading.key}.${keyPath}`, this.props.locationKey);
    } else {
      this.props.onCheckChange(e, this.props.heading.key, this.props.locationKey);
    }
  }

  render() {
    const layers = this.props.layers.map(layer => {
      return (
        <div key={ layer.key }>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="right"
            overlay={<Tooltip>{ strings[layer.label] }</Tooltip>}>
            <div  className="sidebar-item" style={{backgroundColor: layer.style.color}}>
              <Checkbox 
                checked={ layer.isChecked }
                disabled={ this.props.isLoading }
                onChange={e => this.handleOnCheckChange(e, `${layer.key}`)}>
                <div className="d-inline-block text-nowrap">
                  <i className={"icon icon-lg icon-"+layer.key}></i>
                  { layer.isChecked ? 
                    <span className="pl-2">
                      <span className={layer.style.color==='#FFFFFF'?"text-muted":"text-white"}>{ strings[layer.label] }</span>
                      <span className="map-legend" style={{backgroundColor: layer.style.color}}></span>
                    </span>
                  : null}
                </div>
              </Checkbox>
            </div>  
          </OverlayTrigger>
        </div>
        
      )
    });

    let headings = [];
    if (this.props.heading) {
      headings = this.props.heading.headings.map(heading => {
        const checkChange = (e, keyPath) => {
          this.handleOnCheckChange(e, keyPath, this.props.locationKey)
        }
  
        return (
          <SidebarHeading key={ heading.key }
            isLoading={ this.props.isLoading } 
            heading={ heading }
            locationKey={ this.props.locationKey }
            onCheckChange={ checkChange } />
        )
      });
    }
    

    const selectAllCheckbox = (heading) => {
      return (
        <Checkbox key={ heading.key }
          checked={ heading.isChecked }
          disabled={ this.props.isLoading }
          onChange={e => this.handleOnCheckChange(e, null)}
          onClick={e => e.stopPropagation()}>
          <div className="d-inline-block text-nowrap">
            <div className="text-muted lead d-inline-block line-height-1">
              <Icon type="check-circle" theme="filled"/>
            </div>
            { heading.isChecked ? 
              <span className="pl-2 pr-3">{strings.all}</span> 
            : null }
          </div>
        </Checkbox>
      )
    }

    return (
      this.props.heading ? 
        <div>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="right"
            overlay={<Tooltip>{strings.allLayers}</Tooltip>}>
            <div className="sidebar-item">
              { selectAllCheckbox(this.props.heading) }
            </div>
          </OverlayTrigger>

          { layers }
          { headings }
        </div>  
      : 
        <div>
          { layers }
        </div>
    );
  }
}

SidebarHeading.propTypes = {
  heading: PropTypes.object,
  layers: PropTypes.array,
  locationKey: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCheckChange: PropTypes.func.isRequired,
};

export default SidebarHeading;