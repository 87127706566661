import LocalizedStrings from 'react-localization';

export const languageCodes = Object.freeze({
  en: {
    code: "en",
    label: "English"
  },
  pt: {
    code: "pt",
    label: "Portuguese"
  },
  fr: {
    code: "fr",
    label: "French"
  },
});

export const strings = new LocalizedStrings({
  en: { // English
    language: "Language",
    zones: "Zones",   
    zone: "Zone", 
    all: "All",
    areaM2: "Area (m2)",
    areaKm2: "Area (km2)",
    layers: "Layers",
    protectedAreas: "Protected areas",
    gill_net_fisheries: "Gill-net fisheries",
    subsistence_fisheries: "Subsistence fisheries",
    breeding_zones: "Breeding zones",
    commercial_aquaculture: "Commercial aquaculture",
    semi_industrial_fisheries: "Semi-industrial fisheries",
    sport_fisheries: "Sport fisheries",
    management_zones: "Management zones",
    fisheries: "Fisheries",
    rivers: "Rivers",
    river: "River",
    small_scale_aquaculture: "Small-scale aquaculture",
    zoom: "Zoom",
    contacts: "Contacts",
    about: "About",
    basin: "Basin",
    choose: "Choose",
    allLayers: "All layers",
    partners: "Partners"
  },
  pt: { // Portuguese
    language: "Idioma",
    zones: "Zonas",
    zone: "Zona",    
    all: "Todas",
    areaM2: "Área (m2)",
    areaKm2: "Área (km2)",
    layers: "Camadas (layers)",
    protectedAreas: "Áreas Protegidas",
    gill_net_fisheries: "Pesca com rede de emalhe",
    subsistence_fisheries: "Pesca de subsitência",
    breeding_zones: "Zonas de berçário",
    commercial_aquaculture: "Aquacultura comercial",
    semi_industrial_fisheries: "Pesca semi-industrial",
    sport_fisheries: "Pesca desportiva e recreativa",
    management_zones: "Zonas de gestão",
    fisheries: "Pescas",
    rivers: "Rios",
    river: "Rio",
    small_scale_aquaculture: "Aquacultura de pequena escala",
    zoom: "Zoom",
    contacts: "Contactos",
    about: "Sobre",
    basin: "Bacia",
    choose: "Escolhe",
    allLayers: "Todas as camadas (layers)",
    partners: "Parceiros"
  },
  fr: { // French
    language: "Langue",
    zones: "Zones",  
    zone: "Zone", 
    all: "Tout",
    areaM2: "Superficie (m2)",
    areaKm2: "Superficie (km2)",
    layers: "Couches",
    protectedAreas: "Zones protégées",
    gill_net_fisheries: "Pêcheries au filet maillant",
    subsistence_fisheries: "Pêcheries de subsitance",
    breeding_zones: "Zones de reproduction",
    commercial_aquaculture: "Aquaculture commerciale",
    semi_industrial_fisheries: "Pêcheries semi-industrielle",
    sport_fisheries: "Pêcheries sportives",
    management_zones: "Zones de gestion",
    fisheries: "Pêcheries",
    rivers: "Rivières",
    river: "Rivière",
    small_scale_aquaculture: "Aquaculture à petite échelle",
    zoom: "Zoom",
    contacts: "Contacts",
    about: "À propos",
    basin: "Bassin",
    choose: "Choisir",
    allLayers: "Toutes les coches",
    partners: "Partenaires"
  }
});